// services/SIService.js

import { PART_API_BASE_URLS } from '../config/api-constants/index';
import { API_BASE_URL } from '../config/setting/index';

const SIService = {
  getAllSIs: async () => {
    try {
      const response = await fetch(API_BASE_URL + PART_API_BASE_URLS.GetListSIs);
      if (!response.ok) {
        throw new Error('Failed to fetch SIs');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getSIById: async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/SI/${id}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch SI with id ${id}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createSI: async (SI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/SI`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(SI),
      });
      if (!response.ok) {
        throw new Error('Failed to create SI');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateSI: async (id, SI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/SI/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(SI),
      });
      if (!response.ok) {
        throw new Error(`Failed to update SI with id ${id}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteSI: async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/SI/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`Failed to delete SI with id ${id}`);
      }
      return response.ok;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default SIService;