import React, { useState, useEffect } from 'react';
import './Products.css';
import ProductService from '../../services/productService';
import SIService from '../../services/SIService';
import { Tabs, Collapse } from "antd";

const Products = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [SIs, setSIs] = useState([]);
  const [loadingSI, setLoadingSI] = useState(true);
  const [error, setError] = useState(null);

  const defaultImage = 'https://via.placeholder.com/150';

  const { TabPane } = Tabs;
  const { Panel } = Collapse;

  // Contenu des catégories pour "Matériels Informatiques"
  const hardwareCategories = {
    "Ordinateurs et Composants": [
      "Ordinateurs portables",
      "Processeurs",
      "Cartes graphiques",
      "Disques durs (HDD, SSD)",
      "Mémoire RAM",
    ],
    "Impression et Scanning": [
      "Imprimantes",
      "Scanners",
      "Rouleaux de caisse",
      "Cartouches d’encre et toners",
    ],
    "Périphériques": ["Claviers", "Souris", "Casques", "Webcams", "Moniteurs"],
    "Réseau et Communication": [
      "Routeurs",
      "Switches réseau",
      "Points d’accès Wi-Fi",
      "Adaptateurs réseau",
    ],
    "Stockage et Accessoires": [
      "Clés USB",
      "Disques durs externes",
      "Cartes mémoire",
      "Boîtiers de stockage",
    ],
  };
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const dataProducts = await ProductService.getAllProducts();
        setProducts(dataProducts);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoadingProducts(false);
      }
    };
    const fetchSIs = async () => {
      try {
        const dataSIs = await SIService.getAllSIs();
        setSIs(dataSIs);
      }catch (error) {
        setError(error.message);
      } finally {
        setLoadingSI(false);
      }
    }

    fetchProducts();
    fetchSIs();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter(product =>
    product.name && product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groupByCategory = (categoryName) => {
    return filteredProducts.filter(product => product.category === categoryName);
  };

  if (loadingProducts || loadingSI) {
    return (
      <div className="loading-message">
        <i className="fas fa-spinner fa-spin icon"></i>
        Chargement...
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-message">
        <i className="fas fa-exclamation-triangle icon"></i>
        Erreur: {error}
      </div>
    );
  }

  return (
    <section id="products" className="products-section">
      <h2>Produits</h2>
      <Tabs type="card" defaultActiveKey="1">
        <TabPane tab="Systèmes d'Informations" key="1">
          <input
            type="text"
            placeholder="Rechercher un produit..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
          <div className="product-list">
            {
              SIs.length >0 ? (              
                SIs.map( SI => (
                <div key={SI.id} className="card">
                  <img
                    src={SI.adresse_Image_SI ? SI.adresse_Image_SI : defaultImage}
                    alt={SI.name}
                    className="image"
                  />
                  <h3>{SI.name}</h3>
                  <p>{SI.description}</p>
                  <p className="price price-license">Prix Licence: Selon les fonctionnalités à choisir</p>
                  <p className="price price-subscription">Prix abonnement: Selon pack choisi</p>
                </div>
              ))
            ) : (
              <p>Aucun Système d'information disponible dans cette famille.</p>
            )}
          </div>
        </TabPane>

        <TabPane tab="Matériels Informatiques et Accessoires" key="2">
          <Tabs tabPosition="left" defaultActiveKey="1" style={{ height: 400 }}>
            {Object.entries(hardwareCategories).map(([category, items], index) => (
              <TabPane tab={category} key={index + 1}>
                <Collapse defaultActiveKey={"1"}>
                  {items.map((item, subIndex) => {
                    const itemProducts = groupByCategory(item);
                    return (
                      <Panel header={item} key={subIndex}>
                        {itemProducts.length > 0 ? (
                          itemProducts.map(product => (
                            <div key={product.id} className="card">
                              <img
                                src={product.adresse_Image_Product ? product.adresse_Image_Product : defaultImage}
                                alt={product.name}
                                className="image"
                              />
                              <h3>{product.name}</h3>
                              <p>{product.description}</p>
                              <p className="price">Prix: {product.price} TND</p>
                            </div>
                          ))
                        ) : (
                          <p>Contactez-nous sur : +216 52 172 703, pour plus de détails sur {item}.</p>
                        )}
                      </Panel>
                    );
                  })}
                </Collapse>
              </TabPane>
            ))}
          </Tabs>
        </TabPane>
      </Tabs>
    </section>
  );
};

export default Products;
